@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;700&display=swap");

/* @import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,700");
@import url("https://fonts.cdnfonts.com/css/bebas-neue?styles=17621,17620"); */

:root {
	--adm-black: #2D2E33;
	--adm-teal-light: #31C4C4;
	--adm-teal: #2BABB6;
	--adm-blue: #2FA7CA;
	--adm-grey-light: #F2F3F5;
	--adm-grey: #6E717D;
	--adm-grey-dark: #353942;
	--adm-red: #CA445A;

	--app-min-width: 1500px;
	--chbx-s: 18px;

	--container-w: 1600px;
	--container-padding: 20px;

	--n-container-wdt: 1322px;
	--n-container-hz-pad: 0px;

	--section-spacing: 50px;

	--navbar-h: 94px;
	--navbar-offset: 16px;

	--p-color: #455a64;
	--p-dark-color: #29434e;
	--p-light-color: #718792;
	--s-color: #ff8f00;
	--s-dark-color: #c43e00;
	--dark-green: #0A5D00;
	--green: #4caf50;
	--promo-green: #E9F8F5;
	--yellow: #ffae42;
	--light-green: #c8e6c9;
	--lighter-green: #e8f5e9;
	--red: #e53935;
	--strong-red: #e91919;

	--link-color: #039be5;
	--check-color: #f0f0f0;
	--border-color: #ccc;
	--border-dark-color: #aaa;

	--dark-blue: #365a71;

	/* --new-font: #878c9f; */
	/* --new-font: #a3a7b6; */
	--new-font: #b7c2cd;

	--black: #000;
	--blue: #365a71;
	--box-shadow-radius: 16px;
	--box-shadows: #e5e5e5;
	--teal: #00b5ad;
	--gray: #f6f7fa;
	--gray-1: #e6e7ea;
	--background-grey: #f2f2fa;
	--payu: #a6c307;
	--subtle-green: #d6f2ef;
	--subtle-green-alt: #f6fcfb;
	--subtle-gray: #f9f9f9;
	--subtle-gray-alt: #fafafc;
	--white: #fff;

	--border-radius: 20px;

	--car-search-field-h: 57px;
	--car-search-input-h: 36px;
	--car-search-link-w: 151px;
	--car-search-w: 1200px;

	--input-border-c: #d2d5e2;

	--ck-color-base-foreground: #fff !important;
	--ck-color-base-background: var(--gray) !important;
	--ck-color-base-border: var(--input-border-c) !important;
	--ck-border-radius: 12px !important;

	--gray-0: #fafbfc;
	--gray-2: #e1e4e8;
	--gray-4: #959da5;
	--gray-6: #586069;
	--gray-8: #2f363d;
	--gray-9: #242c33;
	--gray-10: #1b1f23;
}

* {
	font-family: 'DM Sans', sans-serif;
	box-sizing: border-box;
	color: var(--dark-blue);
}

.ck-editor__main a {
	font-weight: bold;
	text-decoration: underline;
}

html,
body {
	color: var(--new-font);
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	margin: 0;
	padding: 0;
	scroll-padding-top: calc(var(--navbar-h) + var(--navbar-offset));
}

html {
	font-size: 1px;
}

body {
	font-size: 14rem;
}

.container {
	max-width: var(--container-w);
	padding: 0 var(--container-padding);
	margin: auto;
}

.relative {
	position: relative;
}

.bt {
	background: white;
	border: none;
	cursor: pointer;
}

h1 {
	font-size: 20rem;
}

h2 {
	font-size: 18rem;
}

h3 {
	font-size: 16rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
	color: var(--black);
}

blockquote {
	border-left: 3px solid var(--input-border-c);
	line-height: 1.6;
	margin-left: 0;
	padding: 10px;
}

a {
	color: inherit;
	text-decoration: none;
}

hr {
	background: var(--box-shadows);
	border: none;
	display: block;
	height: 1px;
	margin-block: 12px;
	width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.flash-message,
.flash-message>i,
.loading-dialog,
.loading-dialog>i,
.loading-dialog>span {
	color: white;
}

.loading-dialog,
.flash-message {
	position: fixed;
	padding: 20px 50px;
	right: 60px;
	text-align: center;
	top: 100px;
	transition: all .3s ease-in-out;
	z-index: 10000;
}

.loading-dialog.message,
.flash-message.message {
	background: var(--teal);
}

.flash-message.error {
	background: var(--red);
}

.flash-message.warning {
	background: var(--yellow);
}

.flash-message i {
	position: absolute;
	right: 15px;
	top: 17px;
}

.clickable {
	cursor: pointer;
	opacity: .8;
}

.clickable:hover {
	opacity: 1;
}

.min-height {
	min-height: calc(100vh - 550px);
}

.max-width {
	max-width: 800px;
}

progress[value] {
	-webkit-appearance: none;
	-moz-appearance: none;
	     appearance: none;
	border-radius: 6px;
	height: 12px;
	overflow: hidden;
}

::-webkit-progress-bar {
	background: var(--gray);
	border: 1px dashed var(--input-border-c);
}

::-webkit-progress-value {
	background: var(--teal);
	-webkit-transition: width .3s;
	transition: width .3s;
}

.loading-dialog {
	padding-left: 32px;
}

.anim-rotate {
	animation: 1s anim-rotate infinite ease-in-out;
}

.grecaptcha-badge {
	visibility: hidden;
}

[role="button"] {
	cursor: pointer;
}

@keyframes anim-rotate {
	from {
		transform: rotate(0deg)
	}

	to {
		transform: rotate(360deg)
	}
}

@media screen and (max-width: 1050px) {
	:root {
		--n-container-hz-pad: 20px;
	}
}

@media screen and (min-width: 801px) {
	h1 {
		font-size: 34rem;
	}

	h2 {
		font-size: 26rem;
	}

	h3 {
		font-size: 19rem;
	}
}
.RouterProgress_wrap__Y8qBy {
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.RouterProgress_bar__ty6gx {
    background: var(--teal);
    height: 2px;
    transition: width 0.5s;
}
.Icon_i__7J_sH {
    margin: 0 6px;
    color: inherit;
    vertical-align: middle;
}
.ToTop_mobile__O9a8d,
.ToTop_totop__4zKAW {
    background: var(--teal);
    border: 1px solid white;
    border-radius: 50%;
    bottom: 30px;
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 30px;
    text-align: center;
    width: 60px;
    z-index: 1000;
}

.ToTop_mobile__O9a8d {
    bottom: 86px;
}

.ToTop_mobile__O9a8d i,
.ToTop_totop__4zKAW i {
    color: white;
    margin-top: -5px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.ToTop_totop__4zKAW {
    animation: ToTop_jump__NADef .6s normal forwards ease-in-out;
}

.ToTop_mobile__O9a8d {
    animation: ToTop_mobile__O9a8d .6s normal forwards ease-in-out;
}

.ToTop_monile__yexTE:hover i,
.ToTop_totop__4zKAW:hover i {
    animation-name: ToTop_pulse__W6avu;
    animation-duration: .3s;
}

@keyframes ToTop_jump__NADef {
    from {
        height: 57px;
        bottom: 30px
    }

    50% {
        height: 63px;
        bottom: 100px
    }

    to {
        height: 60px;
        bottom: 30px
    }
}

@keyframes ToTop_mobile__O9a8d {
    from {
        height: 57px;
        bottom: 60px
    }

    50% {
        height: 63px;
        bottom: 130px
    }

    to {
        height: 60px;
        bottom: 86px;
    }
}

@keyframes ToTop_pulse__W6avu {
    50% {
        transform: scale(1.4)
    }
}
